import { useState } from "react";
import style from './Accordion.module.css';

const Accordion = ({ name, children, isFirst = false }) => {

    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={style.accordion}>

            <div className={`${style.head} ${isOpen ? style.isOpen : ""} ${isFirst ? style.isFirst : ""}`}>
                <p className={style.name}>{name}</p>
                <button className={style.button} onClick={() => setIsOpen(!isOpen)}>
                    <i className={`fa fa-${isOpen ? "angle-up" : "chevron-down"} ${style.logo}`} />
                </button>
            </div>

            {isOpen && <div className={style.body}>
                {children}
            </div>}

        </div>
    );
};

export default Accordion;
